.savingscontainer-loader{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.savingscontainer-wrapper{
    height: calc(100% - 3.7rem);
    width: 100%;
}
.home-fillcontent .savingscontainer-wrapper{
    height: calc(100% - 4.3rem);
}
.savingscontainer-listwrapper{
    width: 100%;
    overflow: auto;
    height: 100%;
    padding: 0.5rem 0;
}

.savingscontainer-list{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem 0.8rem;
    background-color: #00818130;
    border-radius: 5px;
    margin-bottom: 0.8rem;
}
.savingscontainer-listleft{
    display: flex;
    flex-direction: column;
    
}
.savingscontainer-listleft label{
    font-size: 1.2rem ;
    color: #0d9c00;
    margin-bottom: 0.4rem;
}
.savingscontainer-listrighttotal{
    font-size: 1.3rem;
    color: #0d9c00;
display: flex;
flex-direction: column;
align-items: flex-end;
    margin-right: 4rem;
}
.savingscontainer-addbtn{
   
    background-color: #1f9bd7;
color: #fff;
padding: 0.4rem 1.5rem;
border-radius: 8px;
border: 0;
outline: none;
cursor: pointer;
}


.savingscontainer-header{
display: flex ;
width: 100%;
margin: 0.5rem 0;
justify-content: flex-end;
align-items: center;
}
.home-fillcontent .savingscontainer-header{
    padding: 1rem 0;
    margin: 0;
    margin-bottom: 0.5rem;
}
.savingscontainer-heading{
    margin: 0;
    margin-right: auto;
    font-size: 1.4rem;
   /* font-family: lato-bold; */
    /* margin-right: 0.6rem; */
    cursor: pointer;
}



.savingscontainer-modal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #e4e4e4;
    padding: 2rem;
    z-index: 10;

}
.savingscontainer-modal-header{
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.savingscontainer-modal-body{
    width: 100%;
}

.savingscontainer-form{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
    position: relative;
}
.savingscontainer-modal-lefticon{
transform: rotate(-180deg);
height: 2.2rem;
width: 2.2rem;
cursor: pointer;
margin-right: 0.4rem;
}
.savingscontainer-form label {
    margin-bottom: 0.5rem;
}
.savingscontainer-form input,.savingscontainer-form select  {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #00000049;
    outline: none;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    border-radius: 5px 5px 0 0 ;
}
.savingscontainer-form input:focus,.savingscontainer-form select:focus {
    background-color: #0000000e;
}
.savingscontainer-listrightdelete{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width:3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff00001a;
    border-radius: 0 5px 5px 0;
    }
    .savingscontainer-listrightdelete svg path{
        stroke: red;
    }
    .savingscontainer-listwrapper-header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.7rem;
        background-color: #005f8fa4;
        padding: 0.3rem;
        border-radius: 3px;
    }
    .savingscontainer-listwrapper-headerleft{
        font-size: 1rem;
        color: #ffffff;
        display: flex;
        align-items: center;
        
        
    }
    .savingscontainer-listwrapper-headerleft span{
        color: #ffffff;
        font-size: 1.2rem;
        font-family: Lato-Bold;
        margin-left: 0.3rem ;
    }
    .savingscontainer-listwrapper-headerright{
        font-size: 1rem;
        color: #ffffff;
        align-items: center;
        display: flex;
    }
    .savingscontainer-listwrapper-headerright span{
        color: #ffffff;
        font-size: 1.5rem;
        font-family: Lato-Bold;
        margin-left: 0.3rem ;
    }

@media only screen and (max-width: 600px) {
    .savingscontainer-header{
        padding: 0.3rem;
    }
    .savingscontainer-heading{
        font-size: 1.1rem;
    }
    .savingscontainer-listleft label{
        font-size: 1rem;
    }
    .savingscontainer-listleft p{
        font-size: 0.8rem;
    }
    .savingscontainer-listrighttotal{
        font-size: 1.1rem;
        margin-right: 3rem;
    }
    .savingscontainer-listrightmember{
        margin-right: 0;
    }
    .savingscontainer-form{
        width: 100%;
    }
    .savingscontainer-listrightdelete{
        width: 2.5rem;
    }
    .home-fillcontent .savingscontainer-header{
        padding: 0.3rem;
    }
    .savingscontainer-listwrapper-headerleft{
        font-size: 0.9rem;
    }
    .savingscontainer-listwrapper-headerleft span{
        font-size: 1rem;
    }
    .savingscontainer-listwrapper-headerright{
        font-size: 0.9rem;
    }
    .savingscontainer-listwrapper-headerright span{
        font-size: 1.1rem;
    }
}

.savingscontainer-listleft-user{
    font-size: 0.9rem;
    display: flex;
    align-items: center;
}
.savingscontainer-listleft-user svg{
    margin-right: 0.3rem;
}
.savingscontainer-listleft-user svg path{
    fill: #005f8f;
}
.savingscontainer-listleft-user {
    color: #005f8f;
    margin-bottom: 0.4rem;
}
.savingscontainer-crediticon{
    transform: rotate(140deg);
   
}
.savingscontainer-crediticon path{
    fill: #0d9c00;
}
.savingscontainer-debiticon{
    transform: rotate(-45deg);
}
.savingscontainer-debiticon path{
    fill: #9c0000;
}
.savingscontainer-listright-amountcredit{
color: #0d9c00
}
.savingscontainer-listright-amountdebit{
 color: #9c0000; 
}
.savingscontainer-listright-closeamount{
    color: #181818;
    font-size: 0.7rem;
    margin-top: 0.5rem;
}
.savingscontainer-listleft-date{
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    font-family: Lato-italic;
}
.savingscontainer-listleft-id{
    cursor: pointer ;
    color: #005f8f;
    text-decoration: underline;
}


.savingscontainer-detailmodal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a0a0a59;
    padding: 2rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

}
.savingscontainer-detailmodal-content{
    background-color: #f8f8f8f8;
    border-radius: 5px;
    width: 30rem;
    padding: 1rem;

}
.savingscontainer-detailmodal-header{
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.savingscontainer-detailmodal-body{
    width: 100%;
}
.savingscontainer-detailmodal-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.savingscontainer-detailmodal-form p {
width: 100%;
font-weight: 600;
}
.savingscontainer-detailmodal-form label {
    font-size: 0.80rem;
    margin-bottom: 0.3rem;
}

.savingscontainer-filterbtn{
    border: none;
    background-color: transparent;
    padding: 0  0.5rem;
    cursor: pointer;
}
.savingscontainer-addiconbtn svg, .savingscontainer-filterbtn svg{
    height: 24px;
    width: 24px;
}
.savingscontainer-filterbtn svg path{
    fill: #005f8f;
}
.savingscontainer-addiconbtn{
    border: none;
    background-color: transparent;
    padding: 0 0.5rem;
    cursor: pointer;
    margin-left: 0.5rem;

}
.savingscontainer-addiconbtn svg path{
    fill: #005f8f;
}
.savingscontainer-filter-footer{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.savingscontainer-filter-footerbtn{
    width: 47%;
    margin: 0 0.4rem;
    font-size: 0.9rem;
    padding: 0.5rem;
}