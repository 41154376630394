.membercontainer-wrapper{
    height: 100%;
    width: 100%;
}
.membercontainer-tabs{
    width: 100%;
    display: flex;
}
.membercontainer-tabbutton{
    width: 7rem;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: none;
    font-size: 1rem;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    cursor: pointer;

}
.membercontainer-tabbutton:hover{
    background-color: #00aeff13;

}
.membercontainer-tabbuttonactive{
background-color: #00ffff27;
border-bottom: 2px solid #3498db;
}
.membercontainer-body{
    height: calc(100% - 2.1rem);
    overflow: auto;
}

.membercontainer-tabbutton{
    font-size: 0.8rem;
    width: 7rem;
}