.home{
    height: calc(100% - 60px);
    width: 100%;
    position: absolute;
    left: 0;
    top: 60px;
}
.home-header{
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home-wrapper{
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 0 1rem ;
}
.home-heading{
    font-size: 1.4rem;
    margin: 0;
    margin-right: 0.6rem;
    cursor: pointer;
}
.home-content{
    height: calc(100% - 70px);
    width: 100%;
    overflow: auto;
}
.home-fillcontent{
    height: 100%
}
.home-headerleft{
    margin-right: auto;
}

@media only screen and (max-width: 600px) {
    .home{
        height: calc(100% - 115px);
    }
    .home-header{
        padding: 0.3rem;
    }
    .home-heading{
        font-size: 1.1rem;
    }
.home-content{
    height: calc(100% - 40px);
}
.home-fillcontent{
    height: 100%;
}
}