.memberdetails{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    margin: 0 auto;
}
.memberdetails-form{
    width: 90%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.memberdetails-form label {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
}
.memberdetails-form input,.memberdetails-form select  {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #00000049;
    outline: none;
    padding: 0.3rem 1rem;
    font-size: 0.9rem;
    border-radius: 5px 5px 0 0 ;
}
.memberdetails-form input:focus,.memberdetails-form select:focus {
    background-color: #0000000e;
}
.memberdetails-footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 1rem 0 ;
}
.memberdetails-formsubmit{
    background-color: #1f9bd7;
    border-radius: 20px;
    padding: 0.6rem 1.7rem;
    border: 0;
    color: #fff;
    margin-left: 1rem;

}
.memberdetails-header{
    
    font-size: 1.1rem;
    margin: 2rem 0;
    margin-left: 1rem;
    font-family: Lato-bold;
}
.memberdetails-form-password-btn{
    flex-direction: row;
    align-items: flex-end;
}

.memberdetails-image-section{
    width: 100%;
    display: flex;
    justify-content: center;
}
.memberdetails-image-section img{
    width: 100px;
    height:100px;   
    border-radius: 50px;
    border: 2px solid #1f9bd7;
    object-fit: cover;
    cursor: pointer;

}
@media only screen and (max-width: 600px) {
    .memberdetails{
        grid-template-columns: 1fr;
        margin:0;
        width: 100%;
    }
    .memberdetails-footer{
        margin-right: 0;
        width: 100%;
    }
    .memberdetails-form{
        width: 100%;
    }
    .memberdetails-header{
        margin-left: 0;
    }
    .memberdetails-form-password-btn button{
        margin-left: auto;
    }
}


.memberdetails-inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;   
    overflow: hidden;
    /* position: absolute; */
    z-index: -1;
}

.memberdetails-inputfile + label {
    max-width: 100%;
    font-size: 1.25rem;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
}

.no-js .memberdetails-inputfile + label {
    display: none;
}

.memberdetails-inputfile:focus + label,
.memberdetails-inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}


.memberdetails-inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}

.memberdetails-inputfile-6 + label {
    color: #1f9bd7;
}

.memberdetails-inputfile-6 + label {
    border: 1px solid #1f9bd7;
    background-color: #f1e5e6;
    padding: 0;
}

.memberdetails-inputfile-6:focus + label,
.memberdetails-inputfile-6.has-focus + label,
.memberdetails-inputfile-6 + label:hover {
    border-color: #1f9bd7;
}

.memberdetails-inputfile-6 + label span,
.memberdetails-inputfile-6 + label strong {
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
}

.memberdetails-inputfile-6 + label span {
    width: 100%;
    font-size: 0.8rem;
    /* min-height: 2em; */
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
}

.memberdetails-inputfile-6 + label strong {
    /* height: 100%; */
    width: 100%;
    color: #f1e5e6;
    background-color: #1f9bd7;
    display: inline-block;
    font-size: 1rem;
}

.memberdetails-inputfile-6:focus + label strong,
.memberdetails-inputfile-6.has-focus + label strong,
.memberdetails-inputfile-6 + label:hover strong {
    background-color: #1f9bd7;
}

@media screen and (max-width: 50em) {
	.memberdetails-inputfile-6 + label strong {
		display: block;
	}
    .memberdetails-inputfile + label {
        width: 100%;
    }
    .memberdetails-inputfile-6 + label strong {
        width: 100%;
    }
    .memberdetails-inputfile-6 + label span {
        width: 90%;
    }
}
.memberdetails-loader{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.memberdetails-image-container{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: #00000054;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.memberdetails-image-container img{
    border-radius: 50%;
    width: 300px;
    height: 300px;
    object-fit: cover;
}

.memberdetails-image-container p{
    cursor: pointer;
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: #1f9bd7;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    background-color: #f1e5e6;
    border-radius: 3px;
}
.memberdetails-image-container p svg{
    transform: rotate(-180deg);

}
.memberdetails-image-container p svg path{
    stroke: #1f9bd7;
}