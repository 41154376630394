.explore-icon{
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.explore-icon svg{
    height: 40px;
    width: 40px;
}
.explore-icon svg path, explore-icon svg rect {
    fill: #6e6e6e;
}
 .explore-icon svg .payment-icon{
    fill: #E5E5E5;
}

.explore-icon svg .loan-path-rupee{
fill: #fff;
}
.explore-icon svg  .save-svg{
    fill: #fff;
}
.explore-icon svg .repayment-svg{
    fill: #fff;
}
.explore-icon-loans svg .applyloaniconpath{
    fill: #fff;
}
.explore-icon-loans svg .apply-loans-svg{
    fill: #fff;
}
.explore-icon label{
    margin-top: 0.5rem;
    font-size: 0.7rem;
    color: #6e6e6e;
    text-align: center;
}
.explore-container{
    display: grid;
    gap: 1rem;
    padding: 1rem 0;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media only screen and (max-width: 600px) {
    .explore-container{
      
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}