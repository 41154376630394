.dashboard-container{
    height: 100%;
    width: 100%;
    
}
.dashboard-header{
    width: 100%;
    height: 82px;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-family: Lato-bold;

}
.dashboard-header p{
    font-size: 1.8rem;

}
.dashboard-header label{
    font-size: 1rem;
    display: flex;
    align-items: center;

}
.dashboard-header label span{
    font-size: 0.9rem;
    font-family: Lato-italic;
    color: #3f3f3f;
    margin-right: 0.3rem;
}
.dashboard-loader-section{
    height: 82px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-loader-section .spinner__bounce{
    background-color: #078dcf
}
.dashboard-topsection{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
}
.dashboard-topblock{
    background-color: #078ccfc5;
    padding: 1rem;
    border-radius: 3px;
}
.dashboard-topblock-data{
    font-size: 2.5rem;
    color: beige;
    font-family: Lato-bold;
}
.dashboard-topblock-label{
    font-size: 1rem;
    color: beige;
}
.dashboard-addbtn{
   
    background-color: #1f9bd7;
color: #fff;
padding: 0.4rem 1.5rem;
border-radius: 8px;
border: 0;
outline: none;
cursor: pointer;
margin-left: 1rem;
}


.dashboard-modal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #e4e4e4;
    padding: 2rem;
    z-index: 10;
    overflow: auto;

}
.dashboard-modal-header{
    padding: 1rem 0;
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.dashboard-modal-body{
    width: 100%;
}

.dashboard-form{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.dashboard-modal-lefticon{
transform: rotate(-180deg);
height: 2.2rem;
width: 2.2rem;
cursor: pointer;
margin-right: 0.4rem;
}
.dashboard-form label {
    margin-bottom: 0.5rem;
}
.dashboard-form input,.dashboard-form select  {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #00000049;
    outline: none;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    border-radius: 5px 5px 0 0 ;
}
.dashboard-form input:focus,.dashboard-form select:focus {
    background-color: #0000000e;
}

.dashboard-paymentssection-header{
    width: 100%;
    font-size: 1.5rem;
    margin: 1rem 0;
    margin-top: 2rem;
}

.dashboard-payments-buttonsection{
    width: 100%;
    display: flex;
    margin-bottom: 0.4rem;
}
.dashboard-payments-buttonsection button{
    font-size: 1.1rem;
    padding: 0.6rem 1.5rem;
    border-bottom: 4px solid transparent;
    transition: all 0.2s ease-in-out;
}

.dashboard-payments-button-active{
    background-color: #0099ff2d;
    border-bottom: 4px solid #078dcf !important;
}
.dashboard-payments-body{
    width: 100%;
    overflow: auto;
}

.dashboard-payments-body-nodata{
    height: 100px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;

}
.dashboard-payments-body-content{
    padding: 0.6rem;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #3f3f3f36;
}
.dashboard-payments-body-content img{
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 50%;
}
.dashboard-payments-body-content p{
    margin-left: 0.6rem;
    font-size: 0.9rem;
}
.dashboard-payments-body-content span{
    margin-left: auto;
    margin-right: 2rem;
    color: #009100;
    font-weight: 600;
}
.dashboard-payments-body-pendingcontent span{
    color: #ff0000;
    font-weight: 600;
}
@media only screen and (max-width: 600px) {
    .dashboard-topsection{
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .dashboard-topblock-data{
        font-size: 1.1rem;
    }
    .dashboard-topblock-label{
        font-size: 0.7rem;
    }
    .dashboard-header {
        flex-direction: column;
        align-items: flex-start;
    
    }
    .dashboard-header p{
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
    
    }
    .dashboard-header label{
        font-size: 0.9rem;
    
    }
    .dashboard-form{
        width: 90%;
    }

}