.spinner {
    width: 70px;
    text-align: center;
  }
  
  .spinner-overlay__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .spinner__bounce {
    width: 13px;
    height: 13px;
    background-color: #FFFFFF;
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out both;
      animation-delay: 0s;
  }
  
  .spinner__bounce--1 {
    animation-delay: -0.32s;
  }
  
  .spinner__bounce--2 {
    animation-delay: -0.16s;
  }
  
  @keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    } 
    40% {
       transform: scale(1);
    }
  }