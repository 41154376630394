
.message {
    display: flex;
    align-items: flex-start;
    
    margin-bottom: 1rem;
  }
  
  .message-right{
    display: flex;
    flex-direction: column;
    
    background: #d5ebfa;
    border-radius: 5px;
    padding: 0.4rem;
  }
  .message-right span{
    font-size: 0.6rem;
    color: #0b93f6;
  }
  
  .sent {
    flex-direction: row-reverse;
    align-items: flex-end;
  }
  
  .sent p {
    color: #000;
    align-items: flex-start;
    align-self: flex-end;
  }
  .received p {
    /* background: #e5e5ea; */
    color: black;
  }
  
  .chatroom-list img {
    width: 30px;
    height: 30px;
    background-size: cover;
    border-radius: 50%;
    margin: 2px 5px;
  }
  
  /* .chatroom-form button {
    width: 20%;
    background-color: rgb(56, 56, 143);
  } */
  
  
  .chatroom-form input {
    line-height: 1.5;
    width: calc(100% - 55px);
    margin-right: 1px;
    font-size: 1rem;
    background: rgb(226, 226, 226);
    color: rgb(80, 80, 80);
    outline: none;
    border: none;
    border-radius: 30px;
    padding: 0 10px;
  }
  
  .chatroom-form button {
    background-color: #0b93f6;
    border: none;
    color: white;
    /* padding: 15px 32px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    font-size: 1rem;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .chatroom-form button svg path{
    stroke: #e5e5ea;
  }
  .chatroom-form button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .chatroom-list::-webkit-scrollbar {
    width: 0.25rem;
  }
  
  .chatroom-list::-webkit-scrollbar-track {
    background: #1e1e24;
  }
  
  .chatroom-list::-webkit-scrollbar-thumb {
    background: #6649b8;
  }
  .chatroom-list {
    padding: 10px;
    height: calc(100% - 60px);
    /* margin: 10vh 0 10vh; */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  
  .chatroom-form {
    height: 50px;
    position: fixed;
    bottom: 0;
    /* background-color: rgb(24, 23, 23); */
    width: 100%;
    /* max-width: 728px; */
    display: flex;
    /* font-size: 1.5rem; */
  }

  @media only screen and (max-width: 600px) {
    .chatroom-form {
        height: 45px;
        position: fixed;
        bottom:60px;
        /* background-color: rgb(24, 23, 23); */
        width: 100%;
        left: 0;
        /* max-width: 728px; */
        display: flex;
        justify-content: space-around;
        padding: 0 3px;
        /* font-size: 1.5rem; */
      }
    
  }