.loanscontainer-loader{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loanscontainer-wrapper{
    height: calc(100% - 3.7rem);
    width: 100%;
    overflow: auto;
}
.home-fillcontent .loanscontainer-wrapper{
    height: calc(100% - 4.3rem);
}
.loanscontainer-listwrapper{
    width: 100%;
    overflow: auto;
    height: 100%;
    padding: 0.5rem 0;
}
.loanscontainer-list{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem 0.8rem;
    background-color: #00818130;
    border-radius: 5px;
    margin-bottom: 0.8rem;
}
.loanscontainer-listleft{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.loanscontainer-listleft label{
    font-size: 1.2rem ;
    color: #0d9c00;
    display: flex;
    margin-bottom: 0.4rem;
}
.loanscontainer-listleft p{
    margin-bottom: 0.5rem;

}
.loanscontainer-listrighttotal{
    font-size: 1.3rem;
    color: #0d9c00;

    margin-right: 4rem;
}
.loanscontainer-addbtn{
   
    background-color: #1f9bd7;
color: #fff;
padding: 0.4rem 1.5rem;
border-radius: 8px;
border: 0;
outline: none;
cursor: pointer;
width: auto;
}
.loanscontainer-header{
display: flex ;
width: 100%;
margin: 0.5rem 0;
justify-content: flex-end;
align-items: center;
}
.home-fillcontent .loanscontainer-header{
    padding: 1rem 0;
    margin: 0;
    margin-bottom: 0.5rem;
}
.loanscontainer-heading{
   
    margin: 0;
    margin-right: auto;
    font-size: 1.4rem;
   /* font-family: lato-bold; */
    /* margin-right: 0.6rem; */
    cursor: pointer;
    display: flex;
    align-items: center;
}



.loanscontainer-modal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #e4e4e4;
    padding: 2rem;
    z-index: 10;

}
.loanscontainer-modal-header{
    padding: 1rem 0;
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.loanscontainer-modal-body{
    width: 100%;
}


.loanscontainer-filtermodal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a0a0a59;
    padding: 2rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

}
.loanscontainer-filtermodal-content{
    background-color: #f8f8f8f8;
    border-radius: 5px;
    width: 30rem;
    padding: 1rem;

}
.loanscontainer-filtermodal-header{
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.loanscontainer-filtermodal-body{
    width: 100%;
}
.loanscontainer-deletemodal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a0a0a59;
    padding: 2rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

}
.loanscontainer-deletemodal-content{
    background-color: #f8f8f8f8;
    border-radius: 5px;
    width: 26rem;
    padding: 2rem;
    

}
.loanscontainer-deletemodal-header{
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
   text-align: center;
   margin-bottom: 0.5rem;
}
.loanscontainer-deletemodal-lefticon{
height: 55px;
width: 55px;
}
.loanscontainer-deletemodal-body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #cbd3db;
    border-radius: 5px;
    padding: 0.5rem;
}
.loanscontainer-delete-footer{
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.loanscontainer-delete-deletefooterbtn{
    width: 47%;
    margin: 0 0.4rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    background-color: #da2033;
    color: #ffffff;
}
.loanscontainer-delete-cancelfooterbtn{
    width: 47%;
    margin: 0 0.4rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    background-color: transparent;
    color: #48576a;
    border:1px solid #cbd3db;
}

.loanscontainer-form{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.loanscontainer-delete-form{
    width: 50%;
    margin: 0.3rem 0;
}
.loanscontainer-delete-form label{
 font-size: 0.8rem;
 display: flex;   
}
.loanscontainer-delete-form label span{
    margin-right: 0.3rem;
}
.loanscontainer-modal-lefticon{
transform: rotate(-180deg);
height: 1.8rem;
width: 1.8rem;
cursor: pointer;
margin-right: 0.4rem;
}
.loanscontainer-form label {
    margin-bottom: 0.5rem;
}
.loanscontainer-form input,.loanscontainer-form select  {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #00000049;
    outline: none;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    border-radius: 5px 5px 0 0 ;
}
.loanscontainer-form input:focus,.loanscontainer-form select:focus {
    background-color: #0000000e;
}
.loanscontainer-listrightdelete{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width:3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff00001a;
    border-radius: 0 5px 5px 0;
    }
    .loanscontainer-listrightdelete svg path{
        stroke: red;
    }
    .loanscontainer-listwrapper-header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.7rem;
        background-color: #005f8fa4;
        padding: 0.3rem;
        border-radius: 3px;
    }
    .loanscontainer-listwrapper-headerleft{
        font-size: 1rem;
        color: #ffffff;
        display: flex;
        align-items: center;
        
        
    }
    .loanscontainer-listwrapper-headerleft label{
        margin: 0 0.2rem;
    }
    .loanscontainer-listwrapper-headerleft span{
        color: #ffffff;
        font-size: 1.2rem;
        font-family: Lato-Bold;
        margin-left: 0.3rem ;
    }
    .loanscontainer-listwrapper-headerright{
        font-size: 1rem;
        color: #ffffff;
        align-items: center;
        display: flex;
    }
    .loanscontainer-listwrapper-headerright span{
        color: #ffffff;
        font-size: 1.5rem;
        font-family: Lato-Bold;
        margin-left: 0.3rem ;
    }


@media only screen and (max-width: 600px) {
    .loanscontainer-header{
        padding: 0.3rem;
    }
    .loanscontainer-heading{
        font-size: 1.1rem;
    }
    /* .loanscontainer-listleft label{
        font-size: 1rem;
    } */
    .loanscontainer-listleft p{
        font-size: 0.8rem;
    }
    .loanscontainer-listrighttotal{
        font-size: 1.1rem;
        margin-right: 3rem;
    }
    .loanscontainer-form{
        width: 100%;
    }
    .loanscontainer-listrightdelete{
        width: 2.5rem;
    }
    .home-fillcontent .loanscontainer-header{
        padding: 0.3rem;
    }
    .loanscontainer-listwrapper-headerleft{
        font-size: 0.9rem;
    }
    .loanscontainer-listwrapper-headerleft span{
        font-size: 1rem;
    }
    .loanscontainer-listwrapper-headerright{
        font-size: 0.9rem;
    }
    .loanscontainer-listwrapper-headerright span{
        font-size: 1.1rem;
    }
    .loanscontainer-listrightmember{
        margin-right: 0;
    }
    .loanscontainer-delete-form{
        width: 50%;
        margin: 0.2rem 0;
    }
}

.loanscontainer-detailmodal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a0a0a59;
    padding: 2rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

}
.loanscontainer-detailmodal-content{
    /* background-color: #f8f8f8f8; */
    border-radius: 5px;
    width: 100%;
    padding: 0 1rem;

}
.loanscontainer-detailmodal-header{
    font-size: 1.2rem;
    margin:  1rem 0;
    font-weight: 600;
    display: flex;
    align-items: normal;
}
.loanscontainer-detailmodal-body{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* flex-wrap: wrap; */
}
.loanscontainer-detailmodal-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin:  0.7rem 0.1rem ;
    /* width: 33%; */
    
}
.loanscontainer-detailmodal-form p {
width: 100%;
font-weight: 600;
}
.loanscontainer-detailmodal-form label {
    font-size: 0.80rem;
    margin-bottom: 0.3rem;
}
.loanscontainer-listleft-date{
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    font-family: Lato-italic;
}
.loanscontainer-listleft-id{
    cursor: pointer ;
    color: #005f8f;
    text-decoration: underline;
    display: flex;
}

.loanscontainer-listleft-status{
    display: flex;
    margin-left: 1rem;
    align-items: center;
    font-size: 0.9rem;
    text-decoration:none;
    
}
.loanscontainer-listleft-status span{
    height: 11px;
    width: 11px;
    border-radius: 50%;
    
    margin-right: 0.3rem;
}
.loanscontainer-listleft-status-open span{
    background-color: #0d9c00;
}
.loanscontainer-listleft-status-open {
    color: #0d9c00;
}
.loanscontainer-listleft-status-closed span{
    background-color: #c20000;
}
.loanscontainer-listleft-status-closed {
    color: #c20000;
}

.loanscontainer-listleft-user{
    font-size: 0.9rem;
    display: flex;
    align-items: center;
}
.loanscontainer-listleft-user svg{
    margin-right: 0.3rem;
}
.loanscontainer-listleft-user svg path{
    fill: #005f8f;
}
.loanscontainer-listleft-user {
    color: #005f8f;
    margin-bottom: 0.4rem;
}

.loanscontainer-filterbtn{
    border: none;
    background-color: transparent;
    padding: 0 0.5rem;
    cursor: pointer;
}
.loanscontainer-addiconbtn svg, .loanscontainer-filterbtn svg{
    height: 24px;
    width: 24px;
}
.loanscontainer-filterbtn svg path{
    fill: #005f8f;
}
.loanscontainer-addiconbtn{
    border: none;
    background-color: transparent;
    padding:0 0.5rem;
    cursor: pointer;
    margin-left: 0.5rem;

}
.loanscontainer-addiconbtn svg path{
    fill: #005f8f;
}
.loanscontainer-filter-footer{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.loanscontainer-filter-footerbtn{
    width: 47%;
    margin: 0 0.4rem;
    font-size: 0.9rem;
    padding: 0.5rem;
}
.loanscontainer-listrightdeletedisabled{
    /* background-color: #00000049; */
}
.repaymentcontainer-listrightdeletedisabled svg path{
    stroke: #0000000e !important
}