.applyloan-container{
    height: 100%;
    width: 100%;
    
}

.applyloan-loader{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.applyloan-wrapper{
    height: calc(100% - 3.7rem);
    width: 100%;
}
.home-fillcontent .applyloan-wrapper{
    height: calc(100% - 4.3rem);
}
.applyloan-listwrapper{
    width: 100%;
    overflow: auto;
    height: 100%;
    padding: 0.5rem 0;
}
.applyloan-list{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem 0.8rem;
    background-color: #00818130;
    border-radius: 5px;
    margin-bottom: 0.8rem;
}

.applyloan-detailmodal-form-switch{
    width: 100%;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    margin:  1rem 0 ;
}
.applyloan-detailmodal-form-switch label{
    margin-right: 1rem;
}
.applyloan-list-pending{
    background-color: #ccae0141;
}
.applyloan-list-adminpending{
    background-color: #0d9c0041;
}
.applyloan-listleft{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.applyloan-listleft label{
    font-size: 1.2rem ;
    color: #0d9c00;
    display: flex;
    margin-bottom: 0.4rem;
}
.applyloan-listleft p{
    margin-bottom: 0.5rem;

}
.applyloan-listrighttotal{
    font-size: 1.3rem;
    color: #0d9c00;

    margin-right: 4rem;
}
.applyloan-addbtn{
   
    background-color: #1f9bd7;
color: #fff;
padding: 0.4rem 1.5rem;
border-radius: 8px;
border: 0;
outline: none;
cursor: pointer;
width: auto;
}
.applyloan-header{
display: flex ;
width: 100%;
margin: 0.5rem 0;
justify-content: flex-end;
align-items: center;
}
.home-fillcontent .applyloan-header{
    padding: 1rem 0;
    margin: 0;
    margin-bottom: 0.5rem;
}
.applyloan-heading{
   
    margin: 0;
    margin-right: auto;
    font-size: 1.4rem;
   /* font-family: lato-bold; */
    /* margin-right: 0.6rem; */
    cursor: pointer;
}



.applyloan-modal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #e4e4e4;
    padding: 2rem;
    z-index: 10;

}
.applyloan-modal-header{
    padding: 1rem 0;
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.applyloan-modal-body{
    width: 100%;
}

.applyloan-form{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.applyloan-modal-lefticon{
transform: rotate(-180deg);
height: 2.2rem;
width: 2.2rem;
cursor: pointer;
margin-right: 0.4rem;
}
.applyloan-form label {
    margin-bottom: 0.5rem;
}
.applyloan-form input,.applyloan-form select  {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #00000049;
    outline: none;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    border-radius: 5px 5px 0 0 ;
}
.applyloan-form input:focus,.applyloan-form select:focus {
    background-color: #0000000e;
}
.applyloan-listrightdelete{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width:3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff00001a;
    border-radius: 0 5px 5px 0;
    }
    .applyloan-listrightdelete svg path{
        stroke: red;
    }
    .applyloan-listwrapper-header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.7rem;
        background-color: #005f8fa4;
        padding: 0.3rem;
        border-radius: 3px;
    }
    .applyloan-listwrapper-headerleft{
        font-size: 1rem;
        color: #ffffff;
        display: flex;
        align-items: center;
        
        
    }
    .applyloan-listwrapper-headerleft label{
        margin: 0 0.2rem;
    }
    .applyloan-listwrapper-headerleft span{
        color: #ffffff;
        font-size: 1.2rem;
        font-family: Lato-Bold;
        margin-left: 0.3rem ;
    }
    .applyloan-listwrapper-headerright{
        font-size: 1rem;
        color: #ffffff;
        align-items: center;
        display: flex;
    }
    .applyloan-listwrapper-headerright span{
        color: #ffffff;
        font-size: 1.5rem;
        font-family: Lato-Bold;
        margin-left: 0.3rem ;
    }


@media only screen and (max-width: 600px) {
    .applyloan-header{
        padding: 0.3rem;
    }
    .applyloan-heading{
        font-size: 1.1rem;
    }
    /* .applyloan-listleft label{
        font-size: 1rem;
    } */
    .applyloan-listleft p{
        font-size: 0.8rem;
    }
    .applyloan-listrighttotal{
        font-size: 1.1rem;
        margin-right: 3rem;
    }
    .applyloan-form{
        width: 100%;
    }
    .applyloan-listrightdelete{
        width: 2.5rem;
    }
    .home-fillcontent .applyloan-header{
        padding: 0.3rem;
    }
    .applyloan-listwrapper-headerleft{
        font-size: 0.9rem;
    }
    .applyloan-listwrapper-headerleft span{
        font-size: 1rem;
    }
    .applyloan-listwrapper-headerright{
        font-size: 0.9rem;
    }
    .applyloan-listwrapper-headerright span{
        font-size: 1.1rem;
    }
    .applyloan-listrightmember{
        margin-right: 0;
    }
}

.applyloan-detailmodal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a0a0a59;
    padding: 0.5rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

}
.applyloan-detailmodal-content{
    background-color: #f8f8f8f8;
    border-radius: 5px;
    width: 30rem;
    padding: 1rem;

}
.applyloan-detailmodal-header{
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.applyloan-detailmodal-body{
    width: 100%;
}
.applyloan-detailmodal-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.applyloan-detailmodal-form p {
width: 100%;
font-weight: 600;
}
.applyloan-detailmodal-form label {
    font-size: 0.80rem;
    margin-bottom: 0.3rem;
}
.applyloan-listleft-date{
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    font-family: Lato-italic;
}
.applyloan-listleft-id{
    cursor: pointer ;
    color: #005f8f;
    /* text-decoration: underline; */
    display: flex;
}

.applyloan-listleft-status{
    display: flex;
    margin-left: 1rem;
    align-items: center;
    font-size: 0.9rem;
    text-decoration:none !important
    
}
.applyloan-listleft-status svg{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    
    margin-right: 0.3rem;
}
.applyloan-listleft-status-checked svg path{
    stroke: #0d9c00;
}
.applyloan-listleft-status-checked {
    color: #0d9c00;
}
.applyloan-listleft-status-pending svg path{
    stroke: #0171cc;
}
.applyloan-listleft-status-pending {
    color: #0171cc;
}

.applyloan-listleft-user{
    font-size: 0.9rem;
    display: flex;
    align-items: center;
}
.applyloan-listleft-user svg{
    margin-right: 0.3rem;
}
.applyloan-listleft-user svg path{
    fill: #005f8f;
}
.applyloan-listleft-user {
    color: #005f8f;
    margin-bottom: 0.4rem;
}
.applyloan-listleft-user button{
    padding: 0.4rem 1rem;
    margin-left: 1rem;
    background-color: #005f8fc4;
    color: #ffffffd8;
    border-radius: 0.6rem;
    font-size: 0.75rem;
}
.applyloan-detailmodal-statussection{
    height: 500px;
    overflow: auto;
}
.applyloan-detailmodal-formlist{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #9b9b9b;
    padding-bottom: 0.4rem;
}
.applyloan-detailmodal-formlist svg{
    height: 20px;
    width: 20px;
}
.applyloan-detailmodal-formlist label{
    margin-right: auto;
    margin-bottom: 0;
    margin-left: 0.5rem;
}
.applyloan-detailmodal-formlist-approved{
    font-size: 0.75rem;
    color: #0d9c00;
    margin-right: 1rem;
}
.applyloan-detailmodal-formlist-pending{
    font-size: 0.75rem;
    color: #005f8f;
    margin-right: 1rem;
}
.applyloan-detailmodal-formlist-admin{
    border-bottom: none;
    border: 1px solid #005f8f;
    padding: 0.4rem;
    border-radius: 0.5rem;
}
.applyloan-detailmodal-formlist-admin label{
    color: #0d9c00;
}
.applyloan-filterbtn{
    border: none;
    background-color: transparent;
    padding: 0 0.5rem;
    cursor: pointer;
}
.applyloan-addiconbtn svg, .applyloan-filterbtn svg{
    height: 24px;
    width: 24px;
}
.applyloan-filterbtn svg path{
    fill: #005f8f;
}
.applyloan-addiconbtn{
    border: none;
    background-color: transparent;
    padding:0 0.5rem;
    cursor: pointer;
    margin-left: 0.5rem;

}
.applyloan-addiconbtn svg path{
    fill: #005f8f;
}
.applyloan-filter-footer{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.applyloan-filter-footerbtn{
    width: 47%;
    margin: 0 0.4rem;
    font-size: 0.9rem;
    padding: 0.5rem;
}
.applyloan-filter-footerdelete{
    width: 47%;
    margin: 0 0.4rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    background-color: #fc3a17;
    color: #fff;
}
.applyloan-filter-footercancel{
    width: 47%;
    margin: 0 0.4rem;
    font-size: 0.9rem;
    padding: 0.5rem;
}
.applyloan-filter-footerapprove{
    width: 47%;
    margin: 0 0.4rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    background-color: #0d9c00;
    color: #fff;
}
.applyloan-filter-disabled{
    background-color: #7c7c7c;
}