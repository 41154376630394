.notification-container{
    height: 100%;
    width: 100%;
    
}
.notification-header{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-family: Lato-bold;

}
.notification-header p{
    font-size: 1.8rem;

}
.notification-header label{
    font-size: 1rem;
    display: flex;
    align-items: center;

}
.notification-header label span{
    font-size: 0.9rem;
    font-family: Lato-italic;
    color: #3f3f3f;
    margin-right: 0.3rem;
}

.notification-addbtn{
   
    background-color: #1f9bd7;
color: #fff;
padding: 0.4rem 1.5rem;
border-radius: 8px;
border: 0;
outline: none;
cursor: pointer;
font-size: 0.8rem;
}
.notification-addbtn input{
    height: 0;
    width: 0;
    
}
.notification-content {
    height: calc(100% - 70px);
    width: 100%;
    overflow: auto;
}
.notification-content-block{
    display: flex;
    border-radius: 3px;
    align-items: flex-start;
    padding: 0.5rem 1rem;
    background-color: #3f3f3f21;
    position: relative;
    margin-bottom: 0.5rem;
}
/* .notification-content-blockright{
   
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.9rem;
    color: #3f3f3f;
} */
.notification-content-blockright{
    width: calc(100% - 4rem);
    font-size: 0.90rem;
    display: flex;
    flex-direction: column;
}
.notification-content-blockheader{
    font-family: "Lato-bold";
    margin-bottom: 0.5rem;
    color: #014568;
    
}
.notification-content-blockcontent{

}
.notification-content-blocktime{
    font-size: 0.7rem;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    z-index: 10;
}
.notification-docs-icons{
    width: 21px;
    height: 21px;
    margin-right: 0.5rem;
}
.notification-docs-icons path{
    fill:#014568;;
}
.notification-content-blockleft{
    margin-left: 0;
    position: absolute;
    right: 0;
    width:40px ;
    height: 100%;
    top: 0;
    

}
.notification-addbtn{
   
    background-color: #1f9bd7;
color: #fff;
padding: 0.4rem 1.5rem;
border-radius: 8px;
border: 0;
outline: none;
cursor: pointer;
font-size: 0.8rem;
}
.notification-content-blockleft svg{
    height: 20px;
    width: 20px;
}
.notification-content-blockleft-menu{
    position: absolute;
    top: 110%;
    right: 0;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background-color: #d4d4d4;
    width: 140px;
    border: 1px solid #3f3f3f88;
    z-index: 10;
}
.notification-content-blockleft-menu button{
    padding: 0.6rem;
    font-size: 0.9rem;
    background-color: transparent;
text-align: left;
cursor: pointer;

}
.notification-content-blockleft-menu button a{
    text-decoration: none;
    color: #3f3f3f;
}
.notification-loader{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notification-content-blockleft-delete{
    color: #cc1313;
}
.notification-content-kebabblock{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-detailmodal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a0a0a59;
    padding: 2rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

}
.notification-detailmodal-content{
    background-color: #f8f8f8f8;
    border-radius: 5px;
    width: 30rem;
    padding: 1rem;

}
.notification-detailmodal-header{
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.notification-detailmodal-body{
    width: 100%;
}
.notification-detailmodal-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.notification-detailmodal-form p {
width: 100%;
font-weight: 600;
}
.notification-detailmodal-form label {
    font-size: 0.80rem;
    margin-bottom: 0.3rem;
}
.notification-modal-lefticon{
    transform: rotate(-180deg);
    height: 2.2rem;
    width: 2.2rem;
    cursor: pointer;
    margin-right: 0.4rem;
    }

    .notification-detailmodal-form input{
        font-size: 0.9rem;
        width: 100%;
        color: #3f3f3f;
        padding: 0.5rem;
        border-bottom: 1px solid #3f3f3f;
        border-radius: 3px;
    }
    .notification-detailmodal-renamefooter{
        display: flex;
        justify-content: flex-end;
    }

    .notification-detailmodal-footer{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .notification-detailmodal-footerdwnload{
        outline: none;
        border: none;
        background-color: #1f9bd7;
        font-size: 0.9rem;
        font-family: "Lato-bold";
        border-radius: 0.2rem;
        padding: 0.5rem;
        width: 90%;
        color: #ffffff;
        cursor: pointer;
    }
    .notification-detailmodal-footerdwnload a{
        color: #ffffff;
        text-decoration: none;
    }   
    .notification-detailmodal-footerdelete{
        outline: none;
        border: none;
        background-color: #bb0000b6;
        font-size: 0.9rem;
        font-family: "Lato-bold";
        border-radius: 0.2rem;
        padding: 0.5rem;
        width: 90%;
        margin:  0 0.5rem;
        color: #ffffff;
        cursor: pointer;
    }



    .notification-modal-container{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #e4e4e4;
        padding: 2rem;
        z-index: 10;
    
    }
    .notification-modal-header{
        font-size: 1.4rem;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
    .notification-modal-body{
        width: 100%;
    }
    
    .notification-form{
        width: 30%;
        display: flex;
        flex-direction: column;
        margin:  1rem 0 ;
    }
    .notification-modal-lefticon{
    transform: rotate(-180deg);
    height: 2.2rem;
    width: 2.2rem;
    cursor: pointer;
    margin-right: 0.4rem;
    }
    .notification-form label {
        margin-bottom: 0.5rem;
    }
    .notification-form input,.notification-form select  {
        background-color: transparent;
        border: 0;
        border-bottom: 2px solid #00000049;
        outline: none;
        padding: 0.3rem 1rem;
        font-size: 1rem;
        border-radius: 5px 5px 0 0 ;
    }
    .notification-form input:focus,.notification-form select:focus {
        background-color: #0000000e;
    }

    @media only screen and (max-width: 600px) {
    .notification-form{
        width: 100%;
    }}