.organisations{
    height: calc(100% - 60px);
    width: 100%;
    position: absolute;
    left: 0;
    top: 60px;
}
.organisations-wrapper{
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}
.organisations-header {
    padding: 1rem 0;
    /* margin-bottom: 0.5rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.organisations-headerleft {
    display: flex;
}
.organisations-heading {
    font-size: 1.4rem;
    margin: 0;
    margin-right: 0.6rem;
    cursor: pointer;
}
.organisations-content {
    height: calc(100% - 60px);
    width: 100%;
    overflow: auto;
}
.organisation-flex-content{
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
align-items: flex-start;
}
.organisation-card{
    block-size: 135px;
    width: 23%;
    background-color: #078dcf;
    padding: 1rem;
    border-radius: 2px;
    margin: 0.5rem ;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out;

}
.organisation-card:hover{
    transform: scale(1.05);
}
.organisation-card svg{
    width: 50px;
    height: 50px;
    margin-bottom: 0.5rem;

}
.organisation-card-header{
    font-size: 1rem ;
    /* font-weight: bold; */
    color: #f3f3f3;
    margin-bottom: 0.6rem;
    font-family: Lato-bold;
}
.organisation-card-subheader{
    font-size: 0.8rem;
    color: #f8f7f7;
}
.organisation-card-addlabel{
    font-size: 1.5rem;
    color: #f8f7f7;
}
.organisation-card-addlabel p{
    cursor: pointer;
}
.organisation-card-addsection{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.organisation-card-addsection button{
    padding: 0.4rem 0.6rem ;
    background-color: #f3f3f3;
    border-radius: 2px;
    margin: 0 0.3rem;
    cursor: pointer;
}
.organisation-card-addsection input{
    font-size: 1rem;
    padding: 0.3rem ;
    border-radius: 3px;
    margin-bottom: 0.4rem;
    width: 90%;
}
.organisation-card-addfooter{
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 600px) {
    .organisation-card{
        width: 100%;
        margin: 1rem 0;
        transition: none;
       
    }
    .organisation-card:hover{
        transform: none;
    }
}

.organisation-loader{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    block-size:  135px;
    width: 300px;
}
.organisation-loader .spinner__bounce{
    background-color: #078dcf
}