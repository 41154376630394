.addmember{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    margin: 0 auto;
}
.addmember-form{
    width: 90%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.addmember-form label {
    margin-bottom: 0.5rem;
}
.addmember-form input,.addmember-form select  {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #00000049;
    outline: none;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    border-radius: 5px 5px 0 0 ;
}
.addmember-form input:focus,.addmember-form select:focus {
    background-color: #0000000e;
}
.addmember-footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    width: 80%;
    margin: 0 auto;
    margin-top: 1rem;
}
.addmember-formsubmit{
    background-color: #1f9bd7;
    border-radius: 20px;
    padding: 0.6rem 1.7rem;
    border: 0;
    color: #fff;
    margin-left: 1rem;

}

@media only screen and (max-width: 600px) {
    .addmember{
        grid-template-columns: 1fr;
        margin:0;
        width: 100%;
    }
    .addmember-footer{
        margin-right: 0;
        width: 100%;
    }
    .addmember-form{
        width: 100%;
    }
}