.navbar{
    height: 60px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1f9bd7;
    display: flex;
    align-items: center;
}
.navbar-logo{
    margin-left: 2.5rem;
    width: 280px;
}

.navbar-user{
    
    height: 40px;
    width: 40px;
    
}
.navbar-user-profileimage{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #9b9b9b;
}
.navbar-tabsection{
    margin-left: auto;
    position: relative;
    margin-right: 2rem;
}
.navbar-tab-buttons{
    position: absolute;
    width: 280px;
    top: 110%;
    right: 0;
    z-index: 11;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 0.7rem;
    box-shadow: rgba(40, 53, 71, 0.36) 0px 8px 32px -18px;
}
.navbar-tab-buttons button{
    padding: 0.4rem;
    width: 100%;
    text-align: left;
    cursor: pointer;
    margin-top: 0.5rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    color: #797979 ;
    border-radius: 3px;
    transition: 0.3s ease-in-out all;
}
.navbar-tab-buttons button:hover{
    background-color: #1f9ad7ab;
    color: #fff;
}
.navbar-tab-buttons button:hover svg path{
    fill: #fff;
}
.navbar-tab-buttons button svg{
    margin-right: 0.4rem;
    height: 18px;
    width: 18px;
    transition: 0.3s ease-in-out all;
}
.navbar-tab-buttons button svg path{
    fill: #797979 ;
    transition: 0.3s ease-in-out all;
}
.navbar-tab-content{
    width: 100%;
    display: flex;align-items: center;
    border-bottom: 1px solid #797979;
    padding-bottom: 0.5rem;
}
.navbar-tab-content label{
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
}
.navbar-tab-content p{
    font-size: 0.9rem;
    font-family: Lato-bold;
}
.navbar-tab-contentdata{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.5rem;
    width: calc(100% - 40px -0.5rem);
}
.navbar-arrow{
    height: 40px;
    width: 40px;
    /* margin-left: 1.5rem; */
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    left: 0.5rem;
}
.navbar-arrow path{
    stroke: #fff;
}
.navbarlink-container{
    display: flex;
    align-items: center;
    margin-left: 3rem;
}
.navbarlink-container-label{
    padding: 0.5rem 1rem;
    border-radius: 2px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    
}
.navbarlink-container-label-active{
    border: 1px solid #fff;
}

.navbar-mobile-container{
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 55px;
    width: 100%;
    background-color: #dfdfdf;
    z-index: 10;
    justify-content: center;
    border-top: 2px solid #afafaf ;
}
.navbar-mobile-tabs{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    position: relative;
    transition: 0.5s ease-in-out all;
    /* border-top: 5px solid transparent; */

}
.navbar-mobile-tabs span{
    border-radius: 0 0 4px 4px;
    height: 4px;
    width: 80%;
    position: absolute;
    top: 0;
    z-index: 10;
    background-color: #1f9bd7;;

}
.navbar-mobile-tabs svg{
    height: 22px;
    width: 22px;
    margin-bottom: 0.2rem;

}
.navbar-mobile-tabs svg path{
fill: #6e6e6e;
}
.navbar-mobile-tabs label{
    font-size: 0.7rem;
    font-family: lato-bold;
    color: #6e6e6e;
}

.navbar-mobile-tabsactive label{
    color: #1f9bd7;
}
.navbar-mobile-tabsactive svg path{
    fill: #0089ce;
}


.navbar-detailmodal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a0a0a59;
    padding: 1rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

}
.navbar-detailmodal-content{
    background-color: #f8f8f8f8;
    border-radius: 5px;
    width: 30rem;
    padding: 1rem;

}
.navbar-detailmodal-header{
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.navbar-detailmodal-body{
    width: 100%;
}
.navbar-detailmodal-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.navbar-detailmodal-form p {
width: 100%;
font-weight: 600;
}
.navbar-detailmodal-form p button{
    padding: 0.3rem 0.5rem;
    border: 1px solid #0089ce;
    border-radius: 3px;
    color: #0089ce;
    background-color: transparent;
    margin-left: 0.4rem;
}
.navbar-detailmodal-form label {
    font-size: 0.80rem;
    margin-bottom: 0.3rem;
}
.navbar-modal-lefticon{
    transform: rotate(-180deg);
    height: 2.2rem;
    width: 2.2rem;
    cursor: pointer;
    margin-right: 0.4rem;
    }



@media only screen and (max-width: 600px) {
    .navbar-arrow{
        margin-left: 0rem;  
        left: -0.3rem;
    }

.navbar-logo{
    margin-left: 1.5rem;
    width: 190px;
}
.navbar-tabsection{
margin-right: 0.5rem;
}
.navbarlink-container{
    display: none;
}
.navbar-mobile-container{
display: flex;
}

}

@media only screen and (min-width: 600px) and (max-width:1280px) {
    .navbarlink-container{
        display: none;
    }
    .navbar-mobile-container{
        display: flex;
    }
}

