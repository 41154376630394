.members{
    height: calc(100% - 60px);
    width: 100%;
    position: absolute;
    left: 0;
    top: 60px;
}
.members-header{
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.members-wrapper{
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 0 1rem ;
}
.members-heading{
    font-size: 1.4rem;
    margin: 0;
    margin-right: 0.6rem;
    cursor: pointer;
}
.members-content{
    height: calc(100% - 70px);
    width: 100%;
    overflow: auto;
}
.members-gridWrapper{
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat( auto-fill, minmax(210px, 1fr) );
}

.members-headerleft{
    display: flex;
}
.members-headerright-btn{
    background-color: #1f9bd7;
    color: #fff;
    padding: 0.4rem 1.5rem;
    border-radius: 8px;
    border: 0;
    outline: none;
    cursor: pointer;
}
.members-gridcard{
    border-radius: 2px;
    background-color: #799aee4d;
    position: relative;
    padding: 0.7rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.members-edit{
    position: absolute;
    top: 0.7rem;
    right: 0.7rem;
    cursor: pointer;
}
.members-user{
height: 80px;
width: 80px;
margin: 1rem 0;
border-radius: 50px;
border:3px solid #1f9bd7;
object-fit:cover;
}

.members-card-form{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.members-card-formheader{
    font-size: 1.1rem;
    font-family: Lato-bold;
}
.members-card-formlabel{
    font-size: 0.8rem;
    margin-top: 0.3rem;
}
.members-card-formlabel-role{
    text-transform: capitalize;
    
}
@media only screen and (max-width: 600px) {
    .members-header{
        padding: 0.3rem;
    }
    .members-heading{
        font-size: 1.1rem;
    }
.members-content{
    height: calc(100% - 45px);
}
.members{
    height: calc(100% - 115px);
}
}