.documents-container{
    height: 100%;
    width: 100%;
    
}
.documents-header{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-family: Lato-bold;

}
.documents-header p{
    font-size: 1.8rem;

}
.documents-header label{
    font-size: 1rem;
    display: flex;
    align-items: center;

}
.documents-header label span{
    font-size: 0.9rem;
    font-family: Lato-italic;
    color: #3f3f3f;
    margin-right: 0.3rem;
}

.documents-addbtn{
   
    background-color: #1f9bd7;
color: #fff;
padding: 0.4rem 1.5rem;
border-radius: 8px;
border: 0;
outline: none;
cursor: pointer;
font-size: 0.8rem;
}
.documents-addbtn input{
    height: 0;
    width: 0;
    
}
.documents-content {
    height: calc(100% - 70px);
    width: 100%;
    overflow: auto;
}
.documents-content-block{
    display: flex;
    border-radius: 3px;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #3f3f3f21;
    position: relative;
    margin-bottom: 0.5rem;
}
.documents-content-block label{
    width: calc(100% - 4rem);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.9rem;
    color: #3f3f3f;
}
.documents-docs-icons{
    width: 25px;
    height: 25px;
    margin-right: 0.3rem;
}
.documents-content-blockleft{
    margin-left: 0;
    position: absolute;
    right: 0;
    width:40px ;
    height: 100%;
    top: 0;
    

}
.documents-content-blockleft svg{
    height: 20px;
    width: 20px;
}
.documents-content-blockleft-menu{
    position: absolute;
    top: 110%;
    right: 0;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background-color: #d4d4d4;
    width: 140px;
    border: 1px solid #3f3f3f88;
    z-index: 10;
}
.documents-content-blockleft-menu button{
    padding: 0.6rem;
    font-size: 0.9rem;
    background-color: transparent;
text-align: left;
cursor: pointer;

}
.documents-content-blockleft-menu button a{
    text-decoration: none;
    color: #3f3f3f;
}
.documents-loader{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.documents-content-blockleft-delete{
    color: #cc1313;
}
.documents-content-kebabblock{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.documents-detailmodal-container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a0a0a59;
    padding: 2rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

}
.documents-detailmodal-content{
    background-color: #f8f8f8f8;
    border-radius: 5px;
    width: 30rem;
    padding: 1rem;

}
.documents-detailmodal-header{
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.documents-detailmodal-body{
    width: 100%;
}
.documents-detailmodal-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin:  1rem 0 ;
}
.documents-detailmodal-form p {
width: 100%;
font-weight: 600;
}
.documents-detailmodal-form label {
    font-size: 0.80rem;
    margin-bottom: 0.3rem;
}
.documents-modal-lefticon{
    transform: rotate(-180deg);
    height: 2.2rem;
    width: 2.2rem;
    cursor: pointer;
    margin-right: 0.4rem;
    }

    .documents-detailmodal-form input{
        font-size: 0.9rem;
        width: 100%;
        color: #3f3f3f;
        padding: 0.5rem;
        border-bottom: 1px solid #3f3f3f;
        border-radius: 3px;
    }
    .documents-detailmodal-renamefooter{
        display: flex;
        justify-content: flex-end;
    }

    .documents-detailmodal-footer{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .documents-detailmodal-footerdwnload{
        outline: none;
        border: none;
        background-color: #1f9bd7;
        font-size: 0.9rem;
        font-family: "Lato-bold";
        border-radius: 0.2rem;
        padding: 0.5rem;
        width: 90%;
        color: #ffffff;
        cursor: pointer;
    }
    .documents-detailmodal-footerdwnload a{
        color: #ffffff;
        text-decoration: none;
    }   
    .documents-detailmodal-footerdelete{
        outline: none;
        border: none;
        background-color: #bb0000b6;
        font-size: 0.9rem;
        font-family: "Lato-bold";
        border-radius: 0.2rem;
        padding: 0.5rem;
        width: 90%;
        margin:  0 0.5rem;
        color: #ffffff;
        cursor: pointer;
    }