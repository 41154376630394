@font-face {
  font-family: Lato-regular;
  src: url(./assets/Lato/Lato-Regular.ttf);
}
@font-face {
  font-family: Lato-bold;
  src: url(./assets/Lato/Lato-Bold.ttf);
}
@font-face {
  font-family: Lato-italic;
  src: url(./assets/Lato/Lato-Italic.ttf);
}
.App, #root, html,body {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;  
  font-family: Lato-regular;
}
.required:after {
  content:" *";
  color: red;
}
.input-incomplete{
  border-bottom: 2px solid #ff000098 !important;
} 

*{
  box-sizing: border-box;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
}