

.forgot-container{
    height: 100%;
    width: 100%;
    background-color: #1f9bd7;
    text-align: center;
}
.forgot-main-logo{
    height: 130px;
    width: 200px;
    margin-top: 3rem;
    
}
.forgot-login-wrapper{
width: 320px;
margin: 3rem auto 0;

}
.forgot-login-wrapper label{
    font-size: 2rem;
    color: #fff;

}
.forgot-login-formsection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.forgot-login-formsection input{
    width: 90%;
    border: none;
    border-radius: 8px;
    padding: 0.8rem 1rem;
    /* font-size: 0.9rem; */
    color: #fff;
    background-color: #40aadd;
    margin: 0;
    margin-top: 2rem;

}
.forgot-login-formsection input:focus{
outline: 2px solid #fff;

}
.forgot-login-formsection input::placeholder{
    color: #fff;

}
.forgot-login-btn{
width: 100%;
margin: 0;
margin-top: 3rem;
padding: 0.6rem 1rem;
border-radius: 0.7rem;
border: 0;
color: #40aadd;
font-family: Lato-bold;
transition: 0.2s all ease-in-out;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
}

.forgot-forgotpassword-right{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.forgot-forgotpassword-right label{
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-right: 1.5rem;
    color: #ebebeb;
    cursor: pointer;
}